import type { Nullable } from "../../@types/generic";

export const numberToString = (
    value: Nullable<number | string | boolean> | undefined
): string => {
    if (typeof value === "string") {
        return value;
    }
    if (typeof value === "number") {
        return value.toString();
    }
    return "";
};
