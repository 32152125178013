import useSWR, { type SWRResponse } from "swr";
import type { Nullable } from "../../../@types/generic";
import { useCountry } from "../../../components/ApplicationState/hooks";
import { numberToString } from "../../../helpers/formatters/values";
import { QUERY_NAME_LOCATION } from "../../cacheKeys";
import { getLocation } from "../api/locations";
import type { Location } from "../types/locations";

export const useLocation = (
    code: Nullable<string> | undefined
): SWRResponse<Nullable<Location>> => {
    const country = useCountry();
    return useSWR(
        numberToString(code) !== "" ? [QUERY_NAME_LOCATION, code] : null,
        () => getLocation(code as string, country)
    );
};
