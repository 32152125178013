// @see https://dev.to/gugaguichard/replace-clsx-classnames-or-classcat-with-your-own-little-helper-3bf
type Cn = (...a: Array<undefined | null | string | boolean>) => string;

// small clsx replacement, taken from here:
export const cn: Cn = (...args) =>
    args
        .filter(
            (x) =>
                x !== null &&
                x !== undefined &&
                x !== "" &&
                typeof x !== "boolean"
        )
        .join(" ");
