"use client";

import React, { useEffect, useRef, useState } from "react";
import type { Nullable } from "../../../@types/generic";
import { cn } from "../../../helpers/classNames";
import {
    useCountry,
    useIsPersistingForbidden,
} from "../../ApplicationState/hooks";
import { useIsPay } from "../../Consent/hooks";
import { sendError } from "../../ErrorBoundary/helpers";
import type {
    AdSlots,
    AdSlotsATClickPerformance,
    AdSlotsAudienzz,
    AdSlotsCHClickPerformance,
    AdSlotsDEClickPerformance,
    AdSlotsGAM,
    AdSlotsOutbrain,
    AdSlotsSOM,
} from "../types";
import { Audienzz } from "./Audienzz";
import { ClickPerformance } from "./ClickPerformance";
import { ClickPerformanceOutstream } from "./ClickPerformanceOutstream";
import { ClickPerformanceRectangle } from "./ClickPerformanceRectangle";
import { GoogleAdManager } from "./GoogleAdManager";
import { Outbrain } from "./Outbrain";
import { Som } from "./Som";
import styles from "./styles.module.scss";

type Props = {
    slot: {
        de:
            | AdSlotsGAM
            | Exclude<AdSlotsDEClickPerformance, "wetter_premiumstickybanner">
            | AdSlotsOutbrain
            | undefined;
        at:
            | AdSlotsGAM
            | Exclude<
                  AdSlotsATClickPerformance,
                  "WET_AT_M_overlay_stickybanner"
              >
            | AdSlotsSOM
            | AdSlotsOutbrain
            | undefined;
        ch:
            | AdSlotsGAM
            | Exclude<
                  AdSlotsCHClickPerformance,
                  "WET_CH_M_overlay_stickybanner"
              >
            | AdSlotsSOM
            | AdSlotsAudienzz
            | undefined;
        pl: undefined;
    };
    minHeight:
        | {
              de: string | undefined;
              at: string | undefined;
              ch: string | undefined;
              pl: undefined;
          }
        | string;
};

export const AdSlot: React.FC<Props> = ({ slot, minHeight }: Props) => {
    const isPay = useIsPay();
    const wrapperRef = useRef<HTMLDivElement>(null);
    const isPersistingForbidden = useIsPersistingForbidden();
    const country = useCountry();
    const [adSlotElement, setAdSlotElement] =
        useState<Nullable<React.ReactNode>>(null);

    useEffect(() => {
        // use useEffect as ref.current should not be read during render-phase
        if (
            slot[country] !== undefined &&
            ((country === "at" && slot.at !== undefined) ||
                (country === "ch" && slot.ch !== undefined) ||
                (country === "de" && slot.de !== undefined))
        ) {
            setAdSlotElement(
                <SubTree slot={slot[country]} element={wrapperRef.current} />
            );
        }
    }, [country, slot]);

    try {
        if (isPay || isPersistingForbidden || slot[country] === undefined) {
            return null;
        }

        let wrapperClassName: string | undefined = undefined;

        if (
            (country === "at" && slot.at !== undefined) ||
            (country === "ch" && slot.ch !== undefined)
        ) {
            if (
                slot[country].startsWith("WET_AT_M_") ||
                slot[country].startsWith("WET_CH_M_")
            ) {
                wrapperClassName = styles.clickperformanceWrapper;
            } else if (slot[country] === "rectangle1") {
                wrapperClassName = styles.somWrapper;
            }

            return (
                <div
                    style={{
                        minHeight:
                            typeof minHeight === "string"
                                ? minHeight
                                : minHeight[country],
                    }}
                    className={cn(styles.adSlot, wrapperClassName)}
                    ref={wrapperRef}
                >
                    {adSlotElement}
                </div>
            );
        } else if (country === "de" && slot.de !== undefined) {
            if (
                slot.de === "wetter_premiumrectangle" ||
                slot.de.startsWith("wetter_vorhersage_") ||
                slot.de.startsWith("wetter_overlay")
            ) {
                wrapperClassName = styles.clickperformanceWrapper;
            }

            return (
                <div
                    style={{
                        minHeight:
                            typeof minHeight === "string"
                                ? minHeight
                                : minHeight.de,
                    }}
                    className={cn(styles.adSlot, wrapperClassName)}
                    ref={wrapperRef}
                >
                    {adSlotElement}
                </div>
            );
        }
        return null;
    } catch (e) {
        sendError(e);
        return null;
    }
};

const SubTree: React.FC<{
    slot: Exclude<AdSlots, "wetter_premiumstickybanner">;
    element: Nullable<HTMLDivElement>;
}> = ({ slot, element }) => {
    if (slot === "wetter_direct_toolbar") {
        return <GoogleAdManager />;
    } else if (slot === "mbanner1") {
        return <Som slot={slot} stickyTime={3} element={element} />;
    } else if (slot === "rectangle1" || slot === "minread1") {
        return <Som slot={slot} />;
    } else if (slot === "AR_8" || slot === "MB_5") {
        return <Outbrain slot={slot} />;
    } else if (
        slot === "audienzzForecast" ||
        slot === "audienzzHome" ||
        slot === "audienzzHdLivecams"
    ) {
        return <Audienzz slot={slot} />;
    } else if (["WET_CH_M_outstream", "wetter_web_outstream"].includes(slot)) {
        return <ClickPerformanceOutstream slot={slot} />;
    } else if (
        ["WET_CH_M_premiumrectangle", "wetter_premiumrectangle"].includes(slot)
    ) {
        return <ClickPerformanceRectangle slot={slot} />;
    } else if (
        [
            "WET_AT_M_overlay_performancerectangle1",
            "WET_AT_M_performancebanner3",
            "WET_CH_M_overlay_performancerectangle1",
            "WET_CH_M_performancebanner3",
            "wetter_overlay_performancerectangle1",
            "wetter_vorhersage_performancebanner3",
        ].includes(slot)
    ) {
        return (
            <ClickPerformance slot={slot} className={styles.adSlotSizeMedium} />
        );
    } else if (
        slot.startsWith("wetter_vorhersage_") ||
        slot.startsWith("WET_AT_M_") ||
        slot.startsWith("WET_CH_M_")
    ) {
        return (
            <ClickPerformance slot={slot} className={styles.adSlotSizeLarge} />
        );
    }

    return null;
};
