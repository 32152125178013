"use client";

import React from "react";
import { useConsent, useIsPay } from "../../Consent/hooks";
import { sendError } from "../../ErrorBoundary/helpers";
import type { AdSlots } from "../types";

type Props = {
    slot: AdSlots;
};

export const Outbrain: React.FC<Props> = ({ slot }: Props) => {
    const consent = useConsent();
    const isPay = useIsPay();

    try {
        if (!consent || isPay) {
            return null;
        }

        return <div className="OUTBRAIN" data-widget-id={slot} />;
    } catch (e) {
        sendError(e);
        return null;
    }
};
