"use client";

import React, { type ErrorInfo } from "react";
import { sendError } from "./helpers";

type Props = {
    children: React.ReactNode;
};
type State = { hasError: boolean };

// this ErrorBoundary works only for client side errors. For ssr error, you have to wrap the component in try-catch and call sendError method
export class ErrorBoundary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(): State {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    override componentDidCatch(error: Error, info: ErrorInfo): void {
        sendError(error, {
            "custom.componentStack": info.componentStack ?? "",
        });
    }

    override render(): React.ReactNode {
        if (this.state.hasError) {
            return null;
        }

        return this.props.children;
    }
}
