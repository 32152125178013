import type { Nullable } from "../../../@types/generic";
import { fetcher } from "../index";
import type { CountryType } from "../types/countryType";
import type { Location } from "../types/locations";

export const getLocation = (
    code: string | undefined,
    country: CountryType
): Promise<Nullable<Location>> => {
    if (!code) {
        return Promise.resolve(null);
    }
    return fetcher<Location>(
        { path: `/locations/${code}/` },
        { country }
    ).catch(() => null);
};
