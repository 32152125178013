"use client";

import React, { useEffect, useState } from "react";
import { cn } from "../../../helpers/classNames";
import { getWindow } from "../../../helpers/getWindow";
import { useConsent, useIsPay } from "../../Consent/hooks";
import { sendError } from "../../ErrorBoundary/helpers";
import styles from "./styles.module.scss";

export const GoogleAdManager: React.FC = () => {
    const consent = useConsent();
    const isPay = useIsPay();
    const [isToolbarHidden, setIsToolbarHidden] = useState(true);
    const window = getWindow();

    useEffect(() => {
        const handler = (event: MessageEvent<{ type: string }>): void => {
            if (
                typeof event.data.type === "string" &&
                event.data.type === "wetter_direct_toolbar"
            ) {
                setIsToolbarHidden(false);
            }
        };

        if (window && isToolbarHidden) {
            window.addEventListener("message", handler, { passive: true });
        }

        return (): void => {
            window?.removeEventListener("message", handler);
        };
    }, [isToolbarHidden, window]);

    try {
        if (!consent || isPay) {
            return null;
        }

        return (
            <div
                id="div-gpt-ad-wetter_direct_toolbar"
                data-ad-type="wetter_direct_toolbar"
                data-testid="googleToolbar"
                className={cn(
                    styles.toolbar,
                    isToolbarHidden && styles.toolbarHidden
                )}
            />
        );
    } catch (e) {
        sendError(e);
        return null;
    }
};
