"use client";

import React from "react";
import { cn } from "../../../helpers/classNames";
import { useCountry, usePageType } from "../../ApplicationState/hooks";
import { useConsent, useIsPay } from "../../Consent/hooks";
import { sendError } from "../../ErrorBoundary/helpers";
import { useStickyAdOffsets } from "../hooks";
import type { AdSlotsClickPerformance } from "../types";
import styles from "./styles.module.scss";

type Props = {
    slot: AdSlotsClickPerformance;
};

export const ClickPerformanceRectangle: React.FC<Props> = ({ slot }: Props) => {
    const consent = useConsent();
    const isPay = useIsPay();
    const { top } = useStickyAdOffsets();
    const country = useCountry();
    const pageType = usePageType();

    try {
        if (!consent || isPay) {
            return null;
        }

        return (
            <div
                id={slot}
                className={cn(
                    styles.adSlotSizeLarge,
                    "ad-flex",
                    "ad-fix-sticky",
                    top !== "0" && country === "de" && "top"
                )}
                data-page-type={
                    pageType?.isForecastTodayPage
                        ? "Refresh_REACT_today"
                        : "notset"
                }
            />
        );
    } catch (e) {
        sendError(e);
        return null;
    }
};
