export const QUERY_NAME_AUTOSUGGEST_SEARCH = "autosuggestSearch";
export const QUERY_NAME_CURRENT_WEATHER_NEARBY = "currentWeatherNearby";
export const QUERY_NAME_FORECAST_WEATHER_NEARBY = "forecastWeatherNearby";
export const QUERY_NAME_LIVECAM_BY_ID = "livecamById";
export const QUERY_NAME_LIVECAMS_BY_TAGS = "livecamsByTags";
export const QUERY_NAME_LIVECAMS_NEARBY = "livecamsNearby";
export const QUERY_NAME_LIVECAMS_NEARBY_PAGINATED = "livecamsNearbyPaginated";
export const QUERY_NAME_LIVECAMS_AND_VIDEOS_NEARBY = "livecamsAndVideosNearby";
export const QUERY_NAME_LOCATION_SUMMARY = "locationSummary";
export const QUERY_NAME_LOCATION = "location";
export const QUERY_NAME_SKI_AREA = "skiArea";
export const QUERY_NAME_SKI_AREA_COUNTRIES = "skiAreaCountries";
export const QUERY_NAME_WARNINGS_COMMUNITY_NEARBY = "warningsCommunityNearby";
