import axios, { type AxiosError } from "axios";
import type { CountryType, Nullable } from "../../@types/generic";
import { sendError } from "../../components/ErrorBoundary/helpers";
import { getWindow } from "../../helpers/getWindow";

const window = getWindow();
const headers: Record<string, string> = {
    token: process.env.NEXT_PUBLIC_API_TOKEN ?? "",
    "X-Application-ID": process.env.NEXT_PUBLIC_API_XAPPLICATIONID ?? "",
};

if (!window) {
    headers["Accept-Encoding"] = "gzip";
}

const axiosInstance = axios.create({
    baseURL: process.env.NEXT_PUBLIC_API_BASE_URL,
    headers,
});

export const fetcher = async <T,>(
    query: {
        path: string;
        params?: Record<
            string,
            Nullable<string | Array<string> | number | undefined>
        >;
    },
    config: {
        country: CountryType;
    }
): Promise<T> => {
    const paramsForQuery: Record<string, string> = {};
    Object.entries(query.params ?? {}).forEach(([key, value]) => {
        if (typeof value === "string") {
            paramsForQuery[key] = value;
        } else if (Array.isArray(value)) {
            paramsForQuery[key] = value.join(",");
        } else if (typeof value === "number") {
            paramsForQuery[key] = value.toString();
        }
    });

    let path = query.path;
    if (Object.keys(paramsForQuery).length > 0) {
        path = path.concat(
            `?${new URLSearchParams(paramsForQuery).toString()}`
        );
    }

    let acceptLanguageHeader = "de-DE";
    if (config.country === "at") {
        acceptLanguageHeader = "de-AT";
    } else if (config.country === "ch") {
        acceptLanguageHeader = "de-CH";
    } else if (config.country === "pl") {
        acceptLanguageHeader = "pl-PL";
    }

    return (
        axiosInstance
            .get<T>(path, {
                headers: {
                    "Accept-Language": acceptLanguageHeader,
                },
            })
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(
                        `${response.status.toString()} - ${path} - ${response.statusText}`
                    );
                }

                return response.data;
            })
            // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
            .catch((error: Error | AxiosError | unknown) => {
                if (isAxiosError(error)) {
                    // Access to config, request, and response
                    const errorData = {
                        // eslint-disable-next-line @typescript-eslint/no-base-to-string
                        "custom.axios.config": error.config?.toString() ?? "",
                        "custom.axios.message": error.message,
                    };
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        Object.assign(errorData, {
                            "custom.axios.data": error.response.data,
                            "custom.axios.status":
                                error.response.status.toString(),
                            "custom.axios.headers": error.response.headers,
                        });
                    } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                        // http.ClientRequest in node.js
                        Object.assign(errorData, {
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                            "custom.axios.request": error.request,
                        });
                    }

                    sendError(error.message, errorData);
                    return Promise.reject(error);
                } else if (error instanceof Error) {
                    sendError(error);
                    return Promise.reject(error);
                } else {
                    const newError = new Error(
                        `Unknown error: ${error?.toString() ?? ""}`
                    );
                    sendError(newError);
                    return Promise.reject(newError);
                }
            })
    );
};

const isAxiosError = (error: unknown): error is AxiosError => {
    return axios.isAxiosError(error);
};
