"use client";

import NextScript from "next/script";
import React from "react";
import { useCountry } from "../../ApplicationState/hooks";
import { useConsent, useIsPay } from "../../Consent/hooks";
import { sendError } from "../../ErrorBoundary/helpers";
import type { AdSlots } from "../types";

const slotUUIDs: Record<AudienzzSlots, string> = {
    audienzzForecast: "bd381422-fb7d-40b1-b3cd-1354bbc281f4",
    audienzzHome: "c92739dd-f6f2-4ee7-95bf-97b078ea5964",
    audienzzHdLivecams: "98cb7962-627a-4d1a-96ec-9d63de641b65",
};

type AudienzzSlots = Extract<
    AdSlots,
    "audienzzForecast" | "audienzzHome" | "audienzzHdLivecams"
>;
type Props = {
    slot: AudienzzSlots;
};

export const Audienzz: React.FC<Props> = ({ slot }) => {
    const consent = useConsent();
    const isPay = useIsPay();
    const country = useCountry();

    try {
        if (!consent || isPay || country !== "ch") {
            return null;
        }
        return (
            <>
                <NextScript
                    id={slot}
                    strategy="lazyOnload"
                    dangerouslySetInnerHTML={{
                        __html: `
                        (function() {
                            const wrapper = window.document.querySelector('.audienzz-wrapper');
                            
                            if (wrapper) {
                                const baseScript = window.document.createElement('script');
                                const adScript = window.document.createElement('script');
                                
                                adScript.innerHTML = 'window.audienzz.bc.wrapper({ uuid: "${slotUUIDs[slot]}", });';
                                
                                baseScript.src = 'https://businessclick.ch/index.js';
                                baseScript.defer = true;
                                baseScript.onload = () => {
                                    wrapper.appendChild(adScript);
                                };
                                
                                wrapper.appendChild(baseScript);
                            }
                        })();
                    `,
                    }}
                />
            </>
        );
    } catch (e) {
        sendError(e);
        return null;
    }
};
